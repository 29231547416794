.pagination {
    margin: 30px auto;
    display: flex;
    /* position: absolute; */

    list-style: none;
    outline: none;
  }
  .pagination > .active > a{
    background-color: #404954 ;
    border-color: #404954 ;
    color: #fff;
  }
  .pagination > li > a{
    border: 1px solid #404954 ;
    padding: 5px 10px;
    outline: none;
    cursor: pointer;
  }
  .pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus{
    background-color: #404954 ;
    border-color: #404954;
    outline: none ;
  }
  .pagination > li > a, .pagination > li > span{
    color: #404954
  }
  .pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span{
    border-radius: unset
  }
  .landingPageStyle {
    padding: 20px;
    padding-right:0;
    padding-left:0;
    padding-top: 80px;
    padding-bottom: 80px;
  
}
.landingPageStyle h1{
  align-items: center;
  text-align: center;
  font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
  font-size: 56px;
  font-style: normal;
  font-weight: 600;
  line-height: 64px;
  color: #444444;

}

.landingPageStyle h2{
  align-items: center;
  text-align: center;
  font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px;
  color: #444444;
  padding-top: 0px;
  padding-bottom: 40px;

}
.landingPageStyle h3{
  align-items: center;
  text-align: center;
  font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  color: #444444;
  width: 240px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;


}
.landingPageStyle h4{
  align-items: center;
  text-align: center;
  font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: #444444;
  width: 240px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;

}
.landingPageStyle p{
  align-items: center;
  text-align: center;
  font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: #444444;

}
.landingPageStyle class-col-2 {
  align-items: center;
  text-align: center;
  justify-content: center;
  justify-items: center;
  width: 100%;
}


.homePageStyle {
justify-content: center;
}
.homePageStyle h1{
align-items: center;
text-align: center;
font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
font-size: 56px;
font-style: normal;
font-weight: 600;
line-height: 64px;
color: #444444;

}

.homePageStyle h2{
align-items: center;
justify-content: center;
text-align: center;
font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
font-size: 40px;
font-style: normal;
font-weight: 600;
line-height: 48px;
color: #444444;
padding-top: 40px;
padding-bottom: 40px;
}
.homePageStyle h3{
align-items: center;
justify-content: center;
text-align: center;
font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 28px;
color: #444444;
margin-inline-start: 10px;
margin-inline-end: 10px;
padding-top: 40px;
}
.homePageStyle h4{
align-items: center;
text-align: center;
font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px;
color: #444444;
width: 240px;
margin-inline-start: 0px;
margin-inline-end: 0px;

}
.homePageStyle p{
align-items: center;
text-align: center;
font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px;
color: #444444;

}
.homePageStyle button{
  align-items: center;
text-align: center;
font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px;
color: #444444;
background-color: #2196f3;
size: "lg";
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.call-to-action .outer-box {
  position: relative;
  background-color: rgba(25, 103, 210, 0.07);
  padding: 50px 60px 10px;
  border-radius: 10px;
  overflow: hidden;
}
* {
  margin: 0px;
  padding: 0px;
  border: none;
  outline: none;
  font-size: 100%;
}.sec-title h2, .sec-title .h2 {
  position: relative;
  display: block;
  font-size: 30px;
  line-height: 1.2em;
  color: #202124;
  font-weight: 500;
}.sec-title .text {
  position: relative;
  margin-top: 15px;
  font-size: 15px;
  line-height: 26px;
}.call-to-action .sec-title {
  position: relative;
  margin-bottom: 50px;
}div {
  display: block;
}.call-to-action .sec-title .theme-btn {
  margin-top: 30px;
}
.theme-btn {
  position: relative;
  display: inline-flex;
  transition: all 0.5s ease;
  overflow: hidden;
  justify-content: center;
  align-items: center;
}

.wrapper { 

  overflow:hidden;
}

.wrapper div {
   min-height: 200px;
   padding: 10px;
}
#one {

  float:left; 
  margin-right:20px;
  width:70%;

}
#two { 
  
  overflow:hidden;



}

@media screen and (max-width: 400px) {
   #one { 
    float: none;
    width:auto;
  }
}
a:link, a:href, a:focus, a:visited {
  text-decoration: none;
  }
  .auto-container {
    position: static;
    max-width: 1310px;
    padding: 0px 15px;
    margin: 0 auto;
    width: 100%;
}p, .text {
  font-size: 15px;
  color: #696969;
  line-height: 24px;
  font-weight: 400;
  margin: 0;
}.call-to-action .image-column {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 360px;
  background-repeat: no-repeat;
  background-position: -10px 20px;
  background-size: cover;
}.call-to-action .sec-title .theme-btn {
  margin-top: 30px;
}.btn-style-one {
  position: relative;
  text-align: center;
  white-space: nowrap;
  color: #ffffff;
  background-color: #1967d2;
  font-size: 15px;
  line-height: 20px;
  border-radius: 8px;
  font-weight: 400;
  padding: 18px 35px 15px 35px;
}.call-to-action .sec-title {
  position: relative;
  margin-bottom: 50px;
}.call-to-action {
  position: relative;
}section {
  display: block;
}
