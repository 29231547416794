//
// Header
//

.header {
    -webkit-font-smoothing: antialiased;
background-color: #ffffff !important;
border: 0 solid currentcolor;
box-shadow: rgba(28, 15, 125, .03) 0 14px 24px 0;
box-sizing: border-box;
color: #000;
display: block;
font-family: Inter,-apple-system,BlinkMacSystemFont,sans-serif;
height: 88px;
left: 0;
line-height: 88px;
margin: 0;
padding: 0 56px 0 48px;
position: relative;
top: 0;
width: 100%;
z-index: 100;


}
.bg-grad{
    background-color: #404954 ;
}
.homeHeader {
    position: relative;
    height: 55px;

}