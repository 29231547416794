cnt {
    font-family: Arial, sans-serif;
    background-color: #fafafa;
    margin: 0;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .profile-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
  .profile-picture {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    object-fit: cover;
    background-color: #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #666;
    margin-bottom: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Ajouter une ombre pour un effet de profondeur */;
  }
  .profile-name {
    font-size: 24px;
    font-weight: bold;
    margin: 10px 0 20px 0;
  }
  .btn-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 20px;
    flex-wrap: wrap;
  }
  .btn-wrapper Button {
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    font-weight: bold;
    transition: all 0.3s ease;
    margin: 0 10px; /* Espacement entre les boutons */
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .btn-wrapper Button:hover {
    background-color: #0056b3;
    transform: translateY(-2px); /* Ajout d'un effet de survol */
  }
  .navigation {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    width: 100%;
  }
  .tab {
    padding: 10px 20px;
    margin: 0 10px;
    border: 2px solid #888;
    border-radius: 20px;
    background-color: #f2f2f2;
    color: #333;
    cursor: pointer;
    transition: all 0.3s ease;
    font-weight: bold;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Ajouter des ombres pour un effet de profondeur */
  }
  .tab:hover {
    background-color: #e0e0e0;
    border-color: #666;
    transform: translateY(-2px); /* Ajout d'un effet de survol */
  }
  .tab.active {
    background-color: #0056b3;
    color: #fff;
    border-color: #0056b3;
  }
  .tab-icon {
    margin-right: 8px;
  }
