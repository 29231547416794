@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .btn-update {
        @apply px-5 py-2 border-green-500 border text-black hover:bg-green-500 hover:text-white
    }
    .btn-delete {
        @apply px-5 py-2 border-red-500 border text-black hover:bg-red-500 hover:text-white
    }
    .btn-qs {
        @apply px-5 py-2 border-yellow-500 border text-black hover:bg-yellow-500 hover:text-white
    }
}