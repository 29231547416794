.question {
 color: black;

}
.question:hover{
    background-color: #d0d0d0;
}
.question:active{
    background-color:#d0d0d0 ;
}
.sideIconWrapper{
    margin-right: 0px;
    font-size: 25px;
    width: 35px;
    min-width: 45px;
    height: 45px;
    line-height: 35px;
    text-align: center;
    display: inline-block;

}