.gris{
    padding:5px;
    margin:5px;
    color:#6c7592;
    font-weight:bold
}

.para{
    font-weight:lighter;
    font-style:italic;
    margin:0px 10px;
    color:#6c7592
}

