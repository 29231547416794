.main-content {
  position: relative;
 // margin-left: 80px;

  // Navbar
  .nav-link {
    -webkit-font-smoothing: antialiased;
    font-family: Inter, -apple-system, BlinkMacSystemFont, sans-serif;
    color: #262a3e !important;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    list-style: none;
    
  }
  .navbar-top {
    -webkit-font-smoothing: antialiased;
    background-color: #fafafc !important;
    border: 0 solid currentcolor;
    box-shadow: rgba(28, 15, 125, 0.03) 0 14px 24px 0;
    box-sizing: border-box;
    color: #000 !important;
    display: flex;
    font-family: Inter, -apple-system, BlinkMacSystemFont, sans-serif;
    height: 88px;
    left: 0;
    line-height: 88px;
    margin: 0;
    padding: 0 56px 0 48px;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 101;
  }

  // Container
  .container-fluid {
    @include media-breakpoint-up(md) {
      padding-left: (
        $main-content-padding-x + $grid-gutter-width / 2
      ) !important;
      padding-right: (
        $main-content-padding-x + $grid-gutter-width / 2
      ) !important;
    }
  }
}

.main-content-home {
  position: relative;

  // Navbar
  .navbar-top {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 1;
    background-color: transparent;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  // Container
  .container-fluid {
    @include media-breakpoint-up(md) {
      padding-left: (
        $main-content-padding-x + $grid-gutter-width / 2
      ) !important;
      padding-right: (
        $main-content-padding-x + $grid-gutter-width / 2
      ) !important;
    }
  }
}
// Offsets the main content depending on the sidebar positioning

.navbar-vertical.navbar-expand {
  @each $breakpoint, $dimension in $grid-breakpoints {
    &-#{$breakpoint} {
      @include media-breakpoint-up(#{$breakpoint}) {
        // Left
        &.fixed-left + .main-content {
          margin-left: $navbar-vertical-width;
        } // Right
        &.fixed-right + .main-content {
          margin-right: $navbar-vertical-width;
        }
      }
    }
  }
}
